<div class="my_content">
    <h4 class="mb-3" *ngIf="!permissions.analyst_dashboard && !permissions.manager_dashboard">
        Bem-vindo(a) ao Atende!
    </h4>
    <ng-container *ngIf="permissions.analyst_dashboard || permissions.manager_dashboard">
        <div class="container-fluid mt-2">
            <div class="row">
                <div class="col-md-3" *ngIf="permissions.manager_dashboard">
                    <label class="form-label">Analista</label>
                    <select class="form-select" [(ngModel)]="analyst">
                        <option value="null">Todos</option>
                        <option *ngFor="let item of responsibles" [value]="item.id">{{item.name}}</option>
                    </select>
                </div>
                <div class="col-md-2">
                    <label class="form-label">Status</label>
                    <select class="form-select" [(ngModel)]="status">
                        <option value="">Todos</option>
                        <option value="7">Contrato Emitido</option>
                        <option value="9">Contrato Cancelado</option>
                        <option value="603">Cancelada</option>
                        <option value="604">Recusada</option>
                        <option value="1270007">Pendente Análise</option>
                        <option value="1270008">Analisado com Pendências</option>
                        <option value="1270010">Aprovada</option>
                        <option value="1270011">Proposta Inativa</option>
                        <option value="1270012">Retorno de Pendência</option>
                    </select>
                </div>
                <div class="col-md-2">
                    <label class="form-label">Data Inicial</label>
                    <input class="form-control" type="date" [(ngModel)]="startDate"
                        [value]="startDate | date:'yyyy-MM-dd'" (change)="verifyDateFilters()">
                </div>
                <div class="col-md-2">
                    <label class="form-label">Data Final</label>
                    <input class="form-control" type="date" [(ngModel)]="endDate" (change)="verifyDateFilters()">
                </div>
                <div class="col-md-1 d-flex align-items-end">
                    <button class="btn btn-primary w-100" (click)="setCharts()">Buscar</button>
                </div>
            </div>
        </div>

        <hr class="my-2">

        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': page == 1}" (click)="setPage(1)">Estoque</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': page == 2}" (click)="setPage(2)">Produtividade</a>
            </li>
        </ul>

        <h4 class="m-3"
            *ngIf="(inventoryDataIsLoaded() && inventoryDataIsEmpty()) || (productivityDataIsLoaded() && productivityDataIsEmpty()) ">
            Sem dados para mostrar...
        </h4>

        <!-- DASHBOARDS DE ESTOQUE -->
        <ng-container *ngIf="page == 1">
            <ng-container *ngIf="inventoryDataIsLoaded()">
                <div class="row">
                    <div class="card bg-light mb-2">
                        <div class="card-body w-100">
                            <div class="row d-flex flex-row justify-content-around align-items-center">
                                <div class="col-md-7">
                                    <app-chart [dataSet]="statusDataSet" [colors]="['#192753']" type="bar"
                                        variation="horizontal"></app-chart>
                                </div>
                                <div class="col-md-3">
                                    <app-chart [dataSet]="auctionDataSet"
                                        [colors]="['#189918', '#8fdb00', '#f3ea38', '#d10a32', '#192753']"
                                        type="doughnut" [displayLegend]="true"></app-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="card bg-light">
                        <div class="card-body">
                            <div class="row d-flex flex-row justify-content-around align-items-center">
                                <div class="col-md-3">
                                    <app-chart [dataSet]="typeDataSet" [colors]="['#192753']" type="bar"></app-chart>
                                </div>
                                <div class="col-md-5">
                                    <app-chart [dataSet]="monthDataSet" [colors]="['#192753']" type="line"></app-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!inventoryDataIsLoaded()">
                <div class="loader">
                    <div style="text-align: center;">
                        <span class="loader-spiner"></span>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <!-- DASHBOARDS DE PRODUTIVIDADE -->
        <ng-container *ngIf="page == 2">
            <ng-container *ngIf="loadedData.analysisTimeData && loadedData.averageAnalysisTimeData">
                <div class="card bg-light mb-2">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="card bg-light">
                                    <div class="card-body">
                                        <h1 class="text-center">Tempo médio</h1>
                                        <h2 class="text-center" style="color: #192753">{{averageTime}}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex flex-row justify-content-around align-items-center">
                            <div class="col-md-6">
                                <app-chart [dataSet]="analysisTimeDataSet" type="line"
                                    [displayLegend]="true"></app-chart>
                            </div>
                            <div class="col-md-6">
                                <app-chart [dataSet]="averageAnalysisTimeDataSet" type="line"
                                    [colors]="['#192753']"></app-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!loadedData.analysisTimeData || !loadedData.averageAnalysisTimeData">
                <div class="loader">
                    <div style="text-align: center;">
                        <span class="loader-spiner"></span>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</div>