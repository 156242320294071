import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/core/services/core.service';
import { DashboardsService } from 'src/app/core/services/dasboards.service';
import { UserProfileService } from 'src/app/core/services/user.service';
import { ChartDataSet } from 'src/app/standalone components/chart/types/chart-data-set';

@Component({
  selector: 'app-apvs-atende',
  templateUrl: './apvs-atende.component.html',
  styleUrls: ['./apvs-atende.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ApvsAtendeComponent implements OnInit {


  constructor(
    private dashboardService: DashboardsService,
    private changeDetectorRef: ChangeDetectorRef,
    public usr: UserProfileService,
    private coreS: CoreService
  ) { }

  /*  --  ---  --  --  --  --  Charts datasets  --  --  --  --  --  --    --  --  */

  // Estoque
  public statusDataSet: ChartDataSet[] = [];
  public auctionDataSet: ChartDataSet[] = [];
  public monthDataSet: ChartDataSet[] = [];
  public typeDataSet: ChartDataSet[] = [];


  // Produtividade
  public analysisTimeDataSet: ChartDataSet[] = [];
  public averageAnalysisTimeDataSet: ChartDataSet[] = [];
  public averageTime: number = 0;


  /*  --  --  --  --  --  --  --  --  --  --  --  --  --  --  --  --  --  --  --  */

  public loadedData: any = {
    statusData: false,
    auctionData: false,
    monthData: false,
    typeData: false,
    analysisTimeData: false,
    averageAnalysisTimeData: false
  }

  public permissions: any = {
    analyst_dashboard: false,
  }

  // responsibles
  public responsibles: Array<any> = [];

  // filters
  public startDate: string = new Date().toISOString().split('T')[0];
  public endDate: string = new Date().toISOString().split('T')[0];
  public analyst: null | number = null;
  public status: string = "";


  // page controll
  public page: number = 1;

  async ngOnInit() {
    this.permissions.analyst_dashboard = await this.usr.verifyPermission('analyst_dashboard');
    this.permissions.manager_dashboard = await this.usr.verifyPermission('manager_dashboard');
    let oneMonth = 30 * 24 * 60 * 60 * 1000;
    this.startDate = (new Date(Date.now() - oneMonth)).toISOString().split('T')[0];
    if (this.permissions.analyst_dashboard || this.permissions.manager_dashboard)
      this.setCharts();
  }

  setInventoryDataAsLoaded() {
    this.loadedData.statusData = true;
    this.loadedData.monthData = true;
    this.loadedData.typeData = true;
    this.loadedData.auctionData = true;
  }

  unsetInventoryDataAsLoaded() {
    this.loadedData.statusData = false;
    this.loadedData.monthData = false;
    this.loadedData.typeData = false;
    this.loadedData.auctionData = false;
  }

  setProductivityDataAsLoaded() {
    this.loadedData.analysisTimeData = true;
    this.loadedData.averageAnalysisTimeData = true;
  }

  unsetProductivityDataAsLoaded() {
    this.loadedData.analysisTimeData = false;
    this.loadedData.averageAnalysisTimeData = false;
  }

  inventoryDataIsLoaded() {
    return this.loadedData.statusData && this.loadedData.monthData && this.loadedData.typeData && this.loadedData.auctionData;
  }
  productivityDataIsLoaded() {
    return this.loadedData.analysisTimeData && this.loadedData.averageAnalysisTimeData;
  }

  inventoryDataIsEmpty() {
    return this.statusDataSet.length == 0 && this.monthDataSet.length == 0 && this.typeDataSet.length == 0 && this.auctionDataSet.length == 0
  }
  productivityDataIsEmpty() {
    return this.analysisTimeDataSet.length == 0 && this.averageAnalysisTimeDataSet.length == 0
  }

  inventoryDashboard(inventoryDashboardService: any) {
    inventoryDashboardService.subscribe({
      next: (data: any) => {
        if (data.status) {
          this.responsibles = this.permissions.manager_dashboard && this.analyst === null ? data.data.analysts : this.responsibles;
          this.statusDataSet = this.setDataLayout(data.data.statusData);
          this.monthDataSet = this.setDataLayout(data.data.monthData);
          this.typeDataSet = this.setDataLayout(data.data.typeData);
          this.auctionDataSet = this.setDataLayout(data.data.auctionData);
          this.changeDetectorRef.detectChanges();
        }
        this.setInventoryDataAsLoaded();
      },
      error: (error: any) => {
        this.setInventoryDataAsLoaded();
        if (error.error.message)
          this.coreS.message(error.error.message, 'danger', 'top');
        else
          this.coreS.message("Ocorreu um problema devido ao uso máximo de memória. Por favor, tente novamente em alguns minutos.", 'danger', 'top');
      }
    })
  }

  productivityDashboard(productivityDashboardService: any) {
    productivityDashboardService.subscribe({
      next: (data: any) => {
        if (data.status) {
          this.analysisTimeDataSet =
            [
              // ...this.setDataLayout(data.data.analysis_time, 'line', '#d10a32'), // comentado até segunda ordem
              ...this.setDataLayout(data.data.number_of_proposals, 'bar', '#192753')
            ];
          this.responsibles = this.permissions.manager_dashboard && this.analyst === null ? data.data.analysts : this.responsibles;
          this.averageAnalysisTimeDataSet = this.setAvarageDataLayout(data.data.analysis_time, data.data.number_of_proposals);
          this.averageTime = data.data.average_time;
          this.changeDetectorRef.detectChanges();
        }
        this.setProductivityDataAsLoaded();
      },
      error: (error: any) => {
        this.setInventoryDataAsLoaded();
        if (error.error.message)
          this.coreS.message(error.error.message, 'danger', 'top');
        else
          this.coreS.message("Ocorreu um problema... Por favor, tente novamente em alguns minutos.", 'danger', 'top');
      }
    })
  }

  setDataLayout(data: any, type?: string, color?: string) {
    let dashboardData: ChartDataSet[] = [];
    Object.keys(data.data).forEach((key) => {
      dashboardData.push({ key: key, value: data.data[key], label: data.label, type: type, color: color });
    })
    return dashboardData;
  }

  setAvarageDataLayout(data1: any, data2: any) {
    let dashboardData: ChartDataSet[] = [];
    Object.keys(data1.data).forEach((key) => {
      dashboardData.push({ key: key, value: parseInt((data1.data[key] / data2.data[key]).toFixed(2)), label: "Media de tempo de análise por proposta (dias)" });
    })
    return dashboardData;
  }


  setCharts() {

    this.unsetInventoryDataAsLoaded();
    this.unsetProductivityDataAsLoaded();

    let filter: any = { dt_inicio: this.startDate, dt_fim: this.endDate, id_usuario_responsavel: this.analyst };
    if (this.status !== "")
      filter.cd_status = this.status;

    // tentei melhorar de todo jeito possível... porem não deu certo
    switch (this.page) {
      case 1:
        if (this.permissions.manager_dashboard)
          this.inventoryDashboard(this.dashboardService.getInventoryDashboardDataForManager(filter));
        else if (this.permissions.analyst_dashboard)
          this.inventoryDashboard(this.dashboardService.getInventoryDashboardData(filter));
        break;
      case 2:
        if (this.permissions.manager_dashboard)
          this.productivityDashboard(this.dashboardService.getProductivityDashboardDataForManager(filter));
        else if (this.permissions.analyst_dashboard)
          this.productivityDashboard(this.dashboardService.getProductivityDashboardData(filter));
        break;
    }


  }

  setPage(page: number) {
    if (page !== this.page) {
      this.page = page;
      this.setCharts();
    }
  }

  verifyDateFilters() {
    let sixtyDays: number = 60 * 24 * 60 * 60 * 1000;
    let period: number = (new Date(this.endDate)).getTime() - (new Date(this.startDate).getTime());

    if ((new Date(this.endDate)).getTime() < (new Date(this.startDate).getTime())) {
      this.endDate = new Date((new Date(this.startDate)).getTime() + sixtyDays).toISOString().split('T')[0];
      this.coreS.message('Data final não pode ser anterior à data inicial.', 'warning', 'top');
    }

    if (period > sixtyDays) {
      this.endDate = new Date((new Date(this.startDate)).getTime() + sixtyDays).toISOString().split('T')[0];
      this.coreS.message('O intervalo do filtro deve ser de até 60 dias.', 'warning', 'top');
    }

    let datesAreGreatterThanToday: boolean =
      (new Date(this.endDate)).getTime() > (new Date()).getTime() || (new Date(this.startDate)).getTime() > (new Date()).getTime()

    if (datesAreGreatterThanToday) {
      this.endDate = new Date().toISOString().split('T')[0];
      this.startDate = new Date((new Date(this.endDate)).getTime() - sixtyDays).toISOString().split('T')[0];
      this.coreS.message('Data de filtro não pode ser superior a data atual.', 'warning', 'top');
    }


  }

}
